html {
  scroll-behavior: smooth;
}
/* https://coolors.co/4e8dc5-3a405a-d6fff6-efd3d7-feeafa */


body {
  margin: 0px;
  padding: 0px;
  /* font-family: Coolvetica_b,Helvetica,sans-serif; */
}
a {
  text-decoration: none;
}

.content {
  margin: 0px auto 32px;
  padding: 0 16px;
  width: 960px;
  max-width: 960px;
  align-items: center;
}


.flexbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.flexbox * {
  flex: 0 0 48%;
  margin: 0px auto 0px 0px;
  padding: 10px 0px;
}
@media screen and (max-width: 1024px) {

  .content {
    width: 90vw;
    max-width: 90vw;
  }
}

@media screen and (max-width: 575px) {

  .flexbox {
    justify-content: center;
  }

  .flexbox * {
    margin: auto;
  }

  footer{
    text-align: center;
  }
}






























:root {
  --clr-neon: hsl(317 100% 54%);
  --clr-neon: #4E8DC5;
  --clr-bg: hsl(323 21% 16%);
  --blue: #4E8DC5;
}

.neon-section {
  min-height: 100vh;
  display: grid;
  place-items: center;
  background: var(--clr-bg);
  font-family: "Balsamiq Sans", cursive;
  color: var(--clr-neon);
  padding-right: 10rem;
}

.neon-button {
  font-size: 4rem;

  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neon);
  border: var(--clr-neon) 0.125em solid;
  padding: 0.25em 1em;
  border-radius: 0.25em;

  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em currentColor;

  box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);

  position: relative;
}

.neon-button::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;

  transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
  filter: blur(1em);
  opacity: 0.7;
}

.neon-button::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 2em 0.5em var(--clr-neon);
  opacity: 0;
  background-color: var(--clr-neon);
  z-index: -1;
  transition: opacity 100ms linear;
}

.neon-button:hover,
.neon-button:focus {
  color: var(--clr-bg);
  text-shadow: none;
}

.neon-button:hover::before,
.neon-button:focus::before {
  opacity: 1;
}
.neon-button:hover::after,
.neon-button:focus::after {
  opacity: 1;
}
